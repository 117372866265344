import React from "react"
import Layout from "../components/layout"
import "./success.sass"

const Success = () => {
  return (
    <div>
      <Layout>
        <div className="success-wrapper">
          <h1>Success</h1>
          <h4>Thank you for your submission</h4>
        </div>
      </Layout>
    </div>
  )
}

export default Success
